<template>
  <div class="consortium">
    <!-- <div class="top_Img">
      <img :src="pageImg" alt="">
      <div class="tips">{{pageTitle}}</div>
    </div> -->
    <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
      <div class="download">
        <div class="item" v-for="(item,index) of listData" :key="index">
          <div class="title" style="width:calc(100% - 320px);">
            <div class="T" style="font-size:20px;font-weight:bold;">{{item.title.substring(0,50)}}<span v-if="item.title.length > 50">...</span></div>
            <div class="message" style="font-size:12px;">
              <span style="margin-right:15px;">发布时间：{{item.pubdate}}</span><span>下载量：{{item.hits}}</span>
            </div>
          </div>
          <!-- <a :href="item.cover" :download="item.cover" class="downloadBtn">下载</a> -->
          <!-- <div @click="downLoad(item.cover,item.title)" class="downloadBtn">下载</div> -->
          <div class="downloadBtn" @click="downloadText(item.id,item.title,item)">下载</div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pagination" v-if="listData.length != 0">
        <a-pagination :default-current="1" :total="total" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      searchMessage:{
        "page_index":1,
        "per_page_count":10,
      },
      total:1,
      listData:[],
      loading:true,
    }
  },
  methods:{
    // 获取资料下载数据
    getDownLoad(){
      let ID = this.$route.query.id;
      this.$post('/v1/news/column/' + ID,this.searchMessage).then(res=>{
        this.total = res.all_news_count;
        this.listData = res.data;
        this.$root.$emit('changeImg',ID);
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    // 页码查询
    changePage(page,pageSize){
      this.searchMessage.page_index = page;
      window.scrollTo(0,0)
      this.getDownLoad();
    },
    // 资料文件下载
    downLoad(url,name){
      // window.location.href = url;
      // window.open(url);

      // let alink = document.createElement("a");
      // alink.download=name //文件名,大部分浏览器兼容,IE10及以下不兼容
      // alink.href=url;//创建 url地址
      // alink.click(); //自动点击

      var a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', url); //分割路径，取出最后一个元素
      // a.setAttribute('target', '_blank');
      a.setAttribute('id', 'DownloadFile');
      // 防止反复添加
      if(document.getElementById('DownloadFile')) {
          document.body.removeChild(document.getElementById('DownloadFile'));
      }
      document.body.appendChild(a);
      a.click();
    },
    downloadFile(record, fileName) {
      var request = new XMLHttpRequest();
      request.responseType = "blob";//定义响应类型
      request.open("GET", record);
      request.onload = function () {
        var url = window.URL.createObjectURL(this.response);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName
        a.click();
      }
      request.send();
    },
    // 获取文件后缀
    getType(file){
      var filename=file;
      var index1=filename.lastIndexOf(".");
      var index2=filename.length;
      var type=filename.substring(index1,index2);
      return type;
    },
    // 文件下载
    downloadText(id,name,data){
      let Type = this.getType(data.cover);
      localStorage.setItem('download','ok');
      this.$get('/v1/news/' + id).then(res=>{
        const data = res;
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a');
        // let fileName=res.headers["content-disposition"].split(";")[1].split("=")[1];
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', name + Type);
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link);
        localStorage.removeItem('download');
      }).catch(err=>{
        localStorage.removeItem('download');
      })
    }
  },
  mounted(){
    this.getDownLoad();
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .ant-pagination-item-active a{color: #fff;}
  .consortium{
    .download{max-width: 1400px;margin:0 auto;margin-top:30px;
      .item{display: flex;justify-content: space-between;padding: 0 80px;height: 100px;align-items: center;border-bottom: 1px solid #ececec;
        .downloadBtn{display:inline-block;width: 70px;height: 30px;text-align: center;background: #fbc914;color: #fff;line-height: 30px;border-radius: 4px;cursor: pointer;font-size: 14px;}
      }
    }
    .pagination{max-width: 1400px;margin: 0 auto;display: flex;justify-content: flex-end;margin-top: 16px;}
    a:hover{text-decoration: none;}
  }
</style>